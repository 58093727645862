/* src/styles/ads.css */

/* 共通の設定（必要に応じて） */
.a8-ad-container {
    text-align: center;
}

/* サイドバー広告用のスタイル */
.sidebar-ad-container {
    margin-top: 3rem;
    margin-bottom: -2rem;
}

.sidebar-ad-container-mobile {
    margin-bottom: 0rem;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}


/* ヘッダー広告用のスタイル */
.header-ad-container {
    margin-top: 1rem;
    /* ヘッダーの場合は違う余白設定など */
    margin-bottom: 2rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    margin-bottom: -5rem;
}

.header-ad-container-mobile {
    margin-top: 1rem;
    /* ヘッダーの場合は違う余白設定など */
    margin-bottom: 2rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    margin-bottom: -5rem;

    @media screen and (max-width: 768px) {
        width: 95%;
    }
}

.header-ad-container .a8-banner {
    width: 70%;
    height: auto;
    border: none;
}

.header-ad-container-mobile .a8-banner {
    width: 100%;
    height: auto;
    border: none;
}

.sidebar-ad-container-mobile .a8-banner {
    width: 100%;
    height: auto;
    border: none;
}

/* ランキングリスト内広告用 */
.ranking-ad-container {
    margin: 2rem auto;
    width: 80%;
}

.ranking-ad-container-mobile {
    margin: 2rem auto;
    width: 100%;
}

.ranking-ad-container .a8-banner,
.ranking-ad-container-mobile .a8-banner {
    width: 100%;
    height: auto;
    border: none;
}